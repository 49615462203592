<template>
<!-- 型号管理 -->
  <div class="box100">
    <div class="whiteBg">
      <div class="flexCB">
        <div class="flexAC" style="margin: 16px 0px;">
          <el-input v-model="name"  placeholder="请输入名称" class="inputSearch"></el-input>
          <el-select v-model="eqtid" placeholder="设备类型" class="retSelect">
            <el-option v-for="item in typeList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
          <el-button  class="btnSearch"  @click="handleSearch">搜索</el-button>
          <el-button  class="btnReset"  @click="handleReset">重置</el-button>
        </div>
        <div class="el-icon-plus settingAdd"  @click="handleAdd"><span> 新增</span></div>
      </div>
      <div class="table_cont">
        <el-table
          :data="modelList"
          :header-cell-style="{background:'#F6F9FF'}">
          <el-table-column label="序号" width="80">
            <template #default="scope"><span>{{(page - 1) * size + scope.$index + 1}}</span></template>
          </el-table-column>
          <el-table-column
            label="厂商"
            prop="manufacturer_name">
          </el-table-column>
          <el-table-column
            label="类型"
            prop="equ_type_name">
          </el-table-column>
          <el-table-column
            label="型号"
            prop="name">
          </el-table-column>
          <el-table-column
            label="配置类型"
            prop="mtname">
          </el-table-column>
          <el-table-column
          label="操作">
            <template slot-scope="scope">
              <el-button type="text" size="small"  style="color:#5473E8;" @click="handleEdit(scope.row)">编辑</el-button>
              <el-button type="text" size="small"  style="color:#FF4C4C;" @click="handleDelete(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination
          @current-change="pageChange"
          :current-page="page"
          :page-sizes="[10]"
          :page-size="size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          class="settingPage">
        </el-pagination>
      </div>
    </div>
    <!-- 新增编辑 -->
    <el-dialog :title="dialogName" :visible.sync="fromShow" center width="460px" class="gyDialog" :close-on-click-modal="false" :before-close="FromCancel">
      <el-form :model="modelfrom" :rules="formRules" ref="modelFrom">
        <el-form-item label="厂商" prop="manuid">
          <el-select v-model="modelfrom.manuid" placeholder="请选择厂商" style="width:100%;" :disabled="eqcDisabled">
            <el-option v-for="item in depeList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="类型" prop="eqtid">
          <el-select v-model="modelfrom.eqtid" placeholder="请选择类型" style="width:100%;" :disabled="eqcDisabled">
            <el-option v-for="item in typeList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="型号" prop="name">
          <el-input v-model="modelfrom.name" maxlength="50"  placeholder="请输入型号" :disabled="eqcDisabled"></el-input>
        </el-form-item>
        <el-form-item label="配置类型" prop="mtlist">
          <el-select v-model="modelfrom.mtlist" placeholder="请选择配置类型" style="width:100%;" multiple @change="getMtlist">
            <el-option v-for="item in mtList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="flexCE">
        <div class="settingCancel" @click="FromCancel">取消</div>
        <div class="settingConfirm" @click="FromConfirm">确定</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { modelGet, modelDel, modelAdd, vendorChoose, equtypeDrop, modelConfigDrop, modelCodeUpdate } from '@/api/port.js'
export default {
  data () {
    return {
      name: '',
      eqtid: '',
      typeList: [],
      modelList: [],
      page: 1,
      size: 10,
      total: 0,
      dialogName: '新增',
      fromShow: false,
      modelfrom: {
        name: '',
        manuid: '',
        eqtid: '',
        mtlist: []
      },
      formRules: {
        name: [
          { required: true, trigger: 'blur', message: '型号不能为空' },
          { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'blur' }
        ],
        manuid: [
          { required: true, trigger: 'blur', message: '厂商不能为空' },
        ],
        eqtid: [
          { required: true, trigger: 'blur', message: '类型不能为空' }
        ]
      },
      depeList: [],
      mtList: [],
      eqcDisabled: false
    }
  },
  created () {
    this.init()
    this.getInit()
  },
  methods: {
    init () {
      const params = {
        eqtid: this.eqtid,
        name: this.name,
        page_index: this.page,
        page_size: this.size
      }
      modelGet(params).then(res => {
        if (res.status === 1) {
          this.modelList = res.data.data
          this.total = res.data.total
        } else {
          this.modelList = []
          this.total = 0
        }
      })
    },
    getInit () {
      // 设备类型下拉
      equtypeDrop().then(res => {
        if (res.status === 1) {
          this.typeList = res.data
        }
      })
    },
    ForInit () {
      // 厂商下拉
      vendorChoose({name: this.name}).then(res => {
        if (res.status === 1) {
          this.depeList = res.data
        }
      })
      //配置类型下拉
      modelConfigDrop().then(res => {
        if (res.status === 1) {
          this.mtList = res.data
        }
      })
    },
    // 新增弹窗
    handleAdd () {
      this.fromShow = true
      this.dialogName = '新增'
      this.eqcDisabled = false
      this.getInit()
      this.ForInit()
    },
    // 取消
    FromCancel () {
      this.fromShow = false
      this.$nextTick(() => {
        this.$refs.modelFrom.resetFields()
        this.modelfrom = {}
      })
    },
    // 编辑弹窗
    handleEdit (row) {
      this.fromShow = true
      this.dialogName = '编辑'
      this.eqcDisabled = true
      this.modelfrom = { ...row }
      this.ForInit()
    },
    // 选中的配置类型
    getMtlist (val) {
      this.modelfrom.mtlist = val
    },
    // 提交
    FromConfirm () {
      this.$refs.modelFrom.validate((valid) => {
        if (!valid) return
        const params = {
          name: this.modelfrom.name,
          manuid: this.modelfrom.manuid,
          eqtid: this.modelfrom.eqtid,
          mtlist: this.modelfrom.mtlist
        }
        if (this.dialogName === '新增') {
          modelAdd(params).then(res => {
            if (res.status === 1) {
              this.$message.success(res.message)
              this.FromCancel()
              this.init()
            } else {
              this.$message.error(res.message)
            }
          })
        } else if (this.dialogName === '编辑') {
          modelCodeUpdate({id: this.modelfrom.id, mtlist: this.modelfrom.mtlist}).then(res => {
            if (res.status === 1) {
              this.$message.success(res.message)
              this.FromCancel()
              this.init()
            } else {
              this.$message.error(res.message)
            }
          })
        }
      })
    },
    // 删除
    handleDelete (row) {
      this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        modelDel({ id: row.id }).then(res => {
          if (res.status === 1) {
            this.$message.success(res.message)
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      }).catch(() => {})
    },
    pageChange (page) {
      this.page = page
      this.init()
    },
    handleSearch () {
      this.init()
    },
    handleReset () {
      this.name = ''
      this.eqtid = ''
      this.page = 1
      this.init()
    }
  }
}
</script>

<style scoped>
</style>
